/* global jQuery */
( function ( $ ) {
	// responsive iframe backup strategy. check _media.scss p iframe
	$( 'p:has( iframe )' ).css( {
		position: 'relative',
		'padding-bottom': '56.25%',
		height: '0',
		overflow: 'hidden',
	} );
} )( jQuery );

/* shrink header on scroll */
( function ( $ ) {
  $(window).scroll(function() {
    if ($(document).scrollTop() > 150) {
      $('.navbar').addClass('shrink');
    } else {
      $('.navbar').removeClass('shrink');
    }
  })
} )( jQuery );

/* cookie consent */
window.cookieconsent.initialise({
    container: document.getElementById("cookieconsent"),
    palette:{
     popup: {background: "#44B9B6"},
     button: {background: "#111F32"},
    },
    revokable: true,
    onStatusChange: function(status) {
     console.log(this.hasConsented() ?
      'enable cookies' : 'disable cookies');
    },
    "position": "bottom",
    "theme": "edgeless",
    "secure": true,
    "content": {
      "header": 'Cookies utilisés sur le site!',
      "message": 'Ce site utilise des cookies. En poursuivant votre navigation sur le site, vous acceptez notre utilisation des cookies.',
      "dismiss": 'Fermez ceci!',
      "allow": 'Autorise les cookies',
      "deny": 'Déclin',
      "link": 'Apprendre encore plus',
      "href": 'termes-et-conditions',
      "close": '&#x274c;',
      "policy": 'Politique en matière de cookies',
      "target": '_blank',
      }
   });
   const filters = document.querySelectorAll(".filter-menu button");

   filters.forEach((filter) => {
     filter.addEventListener("click", function () {
       // ==== Filter btn toggle
       let filterBtn = filters[0];
       while (filterBtn) {
         if (filterBtn.tagName === "BUTTON") {
           filterBtn.classList.remove("active");
         }
         filterBtn = filterBtn.nextSibling;
       }
       this.classList.add("active");

       // === filter
       let selectedFilter = filter.getAttribute("data-filter");
       let itemsToHide = document.querySelectorAll(
         `.grid .col:not([data-filter='${selectedFilter}'])`
       );
       let itemsToShow = document.querySelectorAll(
         `.grid [data-filter='${selectedFilter}']`
       );

       if (selectedFilter == "all") {
         itemsToHide = [];
         itemsToShow = document.querySelectorAll(".grid [data-filter]");
       }

       itemsToHide.forEach((el) => {
         el.classList.add("hide");
         el.classList.remove("show");
       });

       itemsToShow.forEach((el) => {
         el.classList.remove("hide");
         el.classList.add("show");
       });
     });
   });

